<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="4">
        <b-card>
          <b-form-group
            id="input-group-name"
            label="Огноо:"
            label-for="input-name"
            description="Хугацаа хэтрэлт татах өдрөө оруулна уу (2022-10-18 аас хойш)"
          >
          <date-picker v-model="actionDate" :config="{format:'YYYY-MM-DD', useCurrent: false}" :placeholder="Огноо"></date-picker>
          
          </b-form-group>
          <b-button variant="primary" c @click="download()">Татах</b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "LoanRepaymentsOverdue.list",
  data: function() {
    return {
      actionDate: null,
    };
  },
  created: function() {
  },
  methods: {
    download() {
      if(this.$data.actionDate) {
        window.open('https://cib-numur.s3.eu-west-1.amazonaws.com/repayment-overdue/Хугацаа_хэтрэлт_'+ this.$data.actionDate +'.xlsx', '_blank') 
      } else {
        this.showToast("Анхаар", "Огноо сонгоогүй байна", "warning");
      }
    },
  },
};
</script>
